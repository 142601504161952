<template>
  <div>
    <div :class="status.startsWith('error') ? 'status error' : 'status ok'">
      {{ status }}
    </div>

    <!-- Options -->
    <div class="options">
      <div>
        <md-button
            class="md-raised md-primary"
            @click="
            selectedSection = '';
            selectedArticle = '';
            addArticle = true;
          "
        >
          add Content
        </md-button>
        <md-button
            class="md-raised"
            @click="addSection()"
            v-if="selectedArticle"
        >
          add Section
        </md-button>
      </div>
      <div class="flexContent">
        <md-field>
          <label for="article">Content</label>
          <md-select
              v-model="selectedArticle"
              name="article"
              id="article"
              @md-selected="
              addArticle = false;
              articleSelected();
            "
          >
            <md-option v-for="a in articles" v-bind:key="a._id" :value="a._id">
              {{ a.material }}: {{ a.title }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="section">Section</label>
          <md-select
              v-model="selectedSection"
              name="section"
              id="section"
              @md-selected="
              addArticle = false;
              checkCreateSection();
            "
          >
            <md-option
                v-for="s in sectionOptions"
                v-bind:key="s._id"
                :value="s._id"
            >
              {{ s._id === "add" ? s.nr : `Section #${s.nr}` }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>

    <!-- Add Article -->
    <transition name="fade">
      <div class="editSection" v-if="addArticle">
        <h2 class="md-title">Add {{ isVideo ? "Practise" : "Knowledge" }}</h2>
        <md-switch class="md-raised md-primary" v-model="isVideo">
          Is this practise?
        </md-switch>
        <md-autocomplete v-model="newArticle.image" :md-options="files">
          <label>Image</label>
        </md-autocomplete>
        <md-field>
          <label>Title</label>
          <md-input v-model="newArticle.title"></md-input>
        </md-field>
        <md-field>
          <label>Subheader</label>
          <md-input v-model="newArticle.subheader"></md-input>
        </md-field>
        <md-autocomplete
            v-model="newArticle.author"
            :md-options="authors.map((e) => e.name)"
        >
          <label>Author</label>
        </md-autocomplete>
        <div class="error">{{ authorError }}</div>
        <md-field>
          <!-- <label>Tags (Separated by ", ")</label>
          <md-input v-model="newArticle.tags"></md-input> -->
          <multiselect
              v-model="value"
              :options="tags"
              :multiple="true"
              :close-on-select="false"
              placeholder="Tags"
          ></multiselect>
          <md-input
              placeholder="New Tag"
              style="margin-left: 2%"
              v-model="newTag"
          ></md-input
          >
          <md-button
              @click="tags.push(newTag), value.push(newTag)"
              style="margin: 0px 8px"
              v-if="newTag"
          >Add
          </md-button
          >
        </md-field>
        <md-field>
          <label>Main Points (One per line)</label>
          <md-textarea
              v-model="newArticle.mainPoints"
              md-autogrow
          ></md-textarea>
        </md-field>
        <md-field>
          <label>Stars (Difficulty)</label>
          <md-input
              type="number"
              min="1"
              max="3"
              v-model="newArticle.stars"
          ></md-input>
        </md-field>
        <div class="error">{{ starsError }}</div>

        <div class="error">{{ addChallengeError }}</div>
        <div class="actions">
          <md-button class="" @click="addArticle = false">Close</md-button>
          <md-button class="md-primary" @click="saveArticle()">Save</md-button>
        </div>
      </div>
    </transition>

    <!-- Edit Section -->
    <transition name="fade">
      <div class="editSection" v-if="activeSection">
        <h2 class="md-title">Edit Section</h2>

        <md-field>
          <label>Section Number</label>
          <md-input
              v-model="activeSection.nr"
              type="number"
              @input="saveChanges(1, 'nr', activeSection.nr)"
          >
          </md-input>
        </md-field>
        <md-autocomplete
            v-model="activeSection.image"
            :md-options="files"
            @input="saveChanges(1, 'image', activeSection.image)"
        >
          <label>Image</label>
        </md-autocomplete>
        <md-field>
          <label>Image Reference</label>
          <md-input
              v-model="activeSection.imageDescription"
              @input="
              saveChanges(1, 'imageDescription', activeSection.imageDescription)
            "
          >
          </md-input>
        </md-field>
        <md-field>
          <label>Title</label>
          <md-input
              v-model="activeSection.title"
              @input="saveChanges(1, 'title', activeSection.title)"
          >
          </md-input>
        </md-field>
        <md-field>
          <label>Text (Supports Markdown)</label>
          <md-textarea
              v-model="activeSection.text"
              @input="saveChanges(1, 'text', activeSection.text)"
              md-autogrow
          >
          </md-textarea>
        </md-field>
        <div class="actions">
          <md-button class="md-raised md-accent" @click="deleteSection()"
          >Delete
          </md-button
          >
        </div>
      </div>
    </transition>

    <!-- Edit Article -->
    <transition name="fade">
      <div class="editSection" v-if="activeArticle && !activeSection">
        <h2 class="md-title">Edit Article</h2>

        <md-autocomplete
            v-model="activeArticle.image"
            :md-options="files"
            @input="saveChanges(0, 'image', activeArticle.image)"
        >
          <label>Image</label>
        </md-autocomplete>
        <md-field>
          <label>Title</label>
          <md-input
              v-model="activeArticle.title"
              @change="saveChanges(0, 'title', activeArticle.title)"
          >
          </md-input>
        </md-field>
        <md-field>
          <label>Subheader</label>
          <md-input
              v-model="activeArticle.subheader"
              @change="saveChanges(0, 'subheader', activeArticle.subheader)"
          >
          </md-input>
        </md-field>
        <md-field>
          <label>Stars (Difficulty)</label>
          <md-input
              type="number"
              min="1"
              max="3"
              v-model="activeArticle.stars"
              @change="saveChanges(0, 'stars', activeArticle.stars)"
          >
          </md-input>
        </md-field>
        <md-field>
          <!-- <label>Tags (Separated by ", ")</label>
          <md-input v-model="newArticle.tags"></md-input> -->
          <multiselect
            v-model="activeArticle.tags"
            :options="tags"
            :multiple="true"
            :close-on-select="false"
            placeholder="Tags"
            @input="saveChanges(0, 'tags', activeArticle.tags)"
          ></multiselect>
          <md-input
            placeholder="New Tag"
            style="margin-left: 2%"
            v-model="newTag"
          ></md-input
          ><md-button
            @click="tags.push(newTag), value.push(newTag)"
            style="margin: 0px 8px"
            v-if="newTag"
            >Add</md-button
          >
        </md-field>
        <div class="error">{{ starsError }}</div>
        <!--        <md-autocomplete-->
        <!--          v-if="expertMode"-->
        <!--          v-model="activeArticle.author"-->
        <!--          :md-options="authors.map(e => e.name)"-->
        <!--          @md-closed="saveChanges(0, 'author', authors.find(e => e.name === activeArticle.author))">-->
        <!--          <label>Author</label>-->
        <!--        </md-autocomplete>-->
        <!--        <md-field v-if="expertMode">-->
        <!--          <label>Tags (Separated by ",")</label>-->
        <!--          <md-input-->
        <!--            v-model="activeArticle.tags"-->
        <!--            @change="saveChanges(0, 'tags', activeArticle.tags.split(','))">-->
        <!--          </md-input>-->
        <!--        </md-field>-->
        <!--        <md-field v-if="expertMode">-->
        <!--          <label>Main Points (Separated by ",")</label>-->
        <!--          <md-textarea-->
        <!--            v-model="activeArticle.mainPoints"-->
        <!--            @change="saveChanges(0, 'mainPoints', activeArticle.mainPoints.split(','))"-->
        <!--            md-autogrow></md-textarea>-->
        <!--        </md-field>-->
        <div class="actions">
          <!--          <md-switch v-model="expertMode" class="md-primary">Expert Mode</md-switch>-->
          <md-switch v-model="preview" class="md-primary"
          >Show Preview
          </md-switch
          >
          <md-switch
              class="md-raised md-primary"
              v-model="activeArticle.published"
              @change="publishArticle()"
          >
            Published
          </md-switch>
          <md-button class="md-raised md-accent" @click="deleteArticle()"
          >Delete
          </md-button
          >
        </div>

        <div class="editSection" v-if="preview">
          <div class="md-title">{{ activeArticle.title }}</div>
          <div class="md-subheading">{{ activeArticle.subheader }}</div>
          <img
              v-if="activeArticle.image"
              :src="activeArticle.image"
              :alt="activeArticle.title"
              style="max-width: 500px"
          />
          <div class="md-subheading">
            Author: {{ activeArticle.author.name }}
          </div>
          <div class="md-subheading" v-if="activeArticle.tags.length">
            Tags: {{ activeArticle.tags.join(", ") }}
          </div>
          Main Points:
          <ul v-if="activeArticle.mainPoints.length">
            <li v-for="p in activeArticle.mainPoints" v-bind:key="p">
              {{ p }}
            </li>
          </ul>
          Difficulty: {{ activeArticle.stars }}
          <div
              v-for="s in activeArticle.content"
              v-bind:key="s._id"
              style="margin-top: 10px"
          >
            <div class="md-body-2" v-if="s.title">{{ s.title }}</div>
            <div v-if="s.text">
              <vue-markdown>{{ s.text }}</vue-markdown>
            </div>
            <img
                v-if="s.image"
                :src="s.image"
                :alt="s.title"
                style="max-width: 500px"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import VueMarkdown from "@adapttive/vue-markdown";
import Multiselect from "vue-multiselect";
import {axiosDelete, axiosGet, axiosPatch, axiosPost} from "@/utils/axiosWrapper";
import {FILES_LOCATION} from "@/config";

export default {
  name: "Articles",
  data() {
    return {
      expertMode: false,
      preview: true,

      addArticle: false,
      isVideo: false,
      articles: [],
      newArticle: {},
      tags: [],

      selectedArticle: "",
      activeArticle: null,
      selectedSection: "",
      activeSection: null,
      sectionOptions: [],

      files: [],
      authors: [],

      status: "",
      authorError: "",
      starsError: "",
      addArticleError: "",
      value: [],
      options: [],
      newTag: null,
    };
  },
  methods: {
    load: function () {
      // Loading Articles
      this.status = "loading data...";
      this.loadArticles()
          .then((a) => {
            //console.log("Articles:", a)
            this.articles = a;
            this.tags = [
              ...new Set(
                  this.articles
                      .map((article) => article.tags)
                      .reduce((a, b) => a.concat(b), [])
                      .sort()
              ),
            ];
            this.articles = this.articles.map((a) => {
              a.content = a.content.sort((a, b) => a.nr - b.nr);
              return a;
            });
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e);
            this.status = "error: loading articles";
          });
      // Loading Files
      this.loadFiles()
          .then((a) => {
            // console.log("Files:", a)
            this.files = a;
          })
          .catch((e) => {
            console.warn(e);
          });
      //Authors
      this.loadAuthors()
          .then((a) => {
            // console.log("Authors:", a)
            this.authors = a;
          })
          .catch((e) => {
            console.warn(e);
          });
    },
    loadArticles: async function () {
      return (
          await axiosGet("/selfSupport/content")
      ).data;
    },
    loadFiles: async function () {
      return (await axiosGet("/files")).data.map(
          (e) => FILES_LOCATION + e
      );
    },
    loadAuthors: async function () {
      return (await axiosGet("/team")).data;
    },
    resetSite: function () {
      this.load();
      this.addArticleError = "";
      this.authorError = "";
      this.starsError = "";
      this.addArticle = false;
      this.sectionOptions = [];
      this.selectedSection = "";
      this.selectedArticle = "";
      this.activeArticle = null;
      this.activeSection = null;
    },
    resetSection: function () {
      this.activeSection = null;
      this.selectedSection = "";
      this.status = "loading articles...";
      this.loadArticles()
          .then((a) => {
            this.articles = a.map((a) => {
              a.content = a.content.sort((a, b) => a.nr - b.nr);
              return a;
            });
            this.sectionOptions = this.articles.find(
                (a) => a._id === this.selectedArticle
            ).content;
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e);
            this.status = "error: loading articles";
          });
    },
    deleteSection: function () {
      this.status = "deleting section...";
      axiosDelete(
          "/selfSupport/section/" + this.selectedSection
      )
          .then(() => {
            this.status = "all set";
            this.resetSection();
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.status = "error: creating section... try again";
          });
    },
    deleteArticle: function () {
      this.status = "deleting knowledge...";
      axiosDelete(
          "/selfSupport/article/" + this.selectedArticle
      )
          .then(() => {
            this.status = "all set";
            this.resetSite();
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.status = "error: creating section... try again";
          });
    },
    saveArticle: async function () {
      this.addArticleError = "";
      this.authorError = "";
      this.starsError = "";
      this.status = "saving...";

      if (this.newArticle.stars > 3 || this.newArticle.stars < 1) {
        this.starsError = "Please enter number between 1-3";
        return;
      }

      let author = this.authors.filter(
          (e) => e.name === this.newArticle.author
      );
      if (author.length !== 1) {
        this.authorError =
            "Author not found, please add any author first to team.";
        return;
      } else {
        this.newArticle.author = author[0];
      }
      this.newArticle.tags = this.value;
      this.value = [];
      if (this.newArticle.mainPoints)
        this.newArticle.mainPoints = this.newArticle.mainPoints.split("\n");
      if (this.isVideo) this.newArticle.material = "Video";

      axiosPost(
          "/selfSupport/article/new",
          this.newArticle
      )
          .then(() => {
            this.resetSite();
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.addArticleError = "Error saving the article... Try later again.";
          })
          .finally(() => (this.status = "all set"));
    },
    publishArticle: function () {
      this.status = "publishing...";
      axiosPatch("/selfSupport/content/publish", {
            published: this.activeArticle.published,
            id: this.selectedArticle,
          })
          .then(() => (this.status = "all set"))
          .catch((e) => {
            console.warn(e);
            this.status = "error: publishing, reload site and try again";
          });
    },
    addSection: function () {
      this.status = "creating new section";
      const section = {
        nr:
            this.articles.find((a) => a._id === this.selectedArticle).content
                .length + 1,
      };
      axiosPost("/selfSupport/section/new", {
            article: this.selectedArticle,
            section: section,
          })
          .then(() => {
            this.resetSection();
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.status = "error: creating section... try again";
          });
    },
    saveChanges: function (kind, part, content) {
      console.warn("test")
      this.status = "saving...";
      this.starsError = "";
      let api = "";
      switch (kind) {
        case 0:
          api = `/selfSupport/article/${this.selectedArticle}/${part}`;
          if (part === "stars" && (content > 3 || content < 1)) {
            this.starsError = "Please enter number between 1-3";
            return;
          }
          break;
        case 1:
          api = `/selfSupport/section/${this.selectedSection}/${part}`;
          break;
      }
      axiosPatch(api, {toChange: content})
          .then(() => {
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e.response);
            this.status = "error: saving changes - reload site";
          });
    },
    articleSelected: function () {
      this.selectedSection = "";
      this.activeSection = null;
      this.sectionOptions = this.articles.find(
          (a) => a._id === this.selectedArticle
      ).content;

      this.activeArticle = this.articles.find(
          (a) => a._id === this.selectedArticle
      );
      this.value = this.activeArticle.tags;
    },
    checkCreateSection: function () {
      this.activeSection = this.articles
          .find((a) => a._id === this.selectedArticle)
          .content.find((s) => s._id === this.selectedSection);
    },
  },
  components: {
    VueMarkdown,
    Multiselect,
  },
  beforeMount: function () {
    this.load();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.status {
  text-align: right;
  opacity: 0.8;
}

.ok {
  color: #00695f;
}

.error {
  color: #ba000d;
}

.editSection {
  margin-top: 2em;
}

.actions {
  text-align: right;
}

.options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexContent {
  width: 800px;
  display: flex;
  flex-direction: row;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
